import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#455c73',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      dark: {
        primary: colors.grey.darken2,
        secondary: colors.grey.darken1,
        accent: colors.purple.darken1,
        success: colors.green.darken3,
        info: colors.blue.darken3,
        error: colors.red.darken3,
      },
    },
  },
});
