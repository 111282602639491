<template>
  <div class="text-center">
    <v-dialog
      v-model="modalComplete.display"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Good game!
        </v-card-title>

        <v-card-text class="pt-5">
          <p>
            Congratulations on completing {{ game.name }}
          </p>
          <p>
            To see other available games <br>
            <a :href="`${url}`" target="_blank">{{url}}</a>
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModal()"
            color="success">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('game', ['modalComplete', 'game']),
    url() {
      return `${window.location.origin}/listgames/${this.game.client_slug}`;
    },
  },
  watch: {
    async modalComplete(value) {
      if (value.display) {
        await this.$store.dispatch('session/pauseTimer');
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('game/showModalComplete', {});
    },
  },
};
</script>
