<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="display"
      absolute
      right
      top
      timeout="3000"
      color="info"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ['display', 'text'],
};
</script>
