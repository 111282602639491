<template>
  <div>
    <div class="v-wrap-bar">
      <div class="v-bar">
      <button @click="toggle=!toggle" class="toggle-nav">
        <span>☰</span>
      </button>
      <div class="title-bar"> Alfakodo {{ (game.name ? `- ${game.name}` : null ) }}</div>
      </div>
      <div v-if="toggle">
        <ul class="nav-list">
          <li><a href="/about" target="_blank">Instructions</a></li>
          <li v-if="game.name" @click="printGame">
            <span>Print Game</span>
          </li>
          <li v-if="game.name" @click="checkGame">
            <span>Check Game</span>
          </li>
          <li v-if="game.name" @click="toggle = false; reset.display = true">
            <span>Reset Game</span>
          </li>
          <li v-if="game.pdf_url" :href="game.pdf_url" target="_blank" @click="printGame()">
            <span>Print Game</span>
          </li>
          <li>
            <a href="/contact" target="_blank">Contact</a>
          </li>
          <li><a :href="'mailto:' + email">Report a bug</a></li>
        </ul>
      </div>
    </div>
    <dia-choose :display="reset.display"
      :text="reset.text"
      :title="reset.title"
      @yes="clearGame(); reset.display=false"
      @no="reset.display=false"></dia-choose>
  </div>
</template>
<style>
  @import '../assets/css/style.css';
</style>

<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import diaChoose from '@/components/diaChoose.vue';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import _ from 'lodash';

export default {
  components: {
    diaChoose,
  },
  data: () => ({
    toggle: false,
    email: 'support@generaltechnical.com.au?subject=Auspac Media Alfakodo Issue',
    reset: {
      display: false,
      text: 'Are you sure you want to reset? All progress will be lost.',
      title: 'Reset game.',
    },
  }),
  computed: {
    ...mapState('game', ['game', 'checkedGame', 'listCheckedGame']),
    ...mapState('session', ['session', 'answers']),
    ...mapGetters('game', ['isClueRevealed', 'getBySide', 'getAnswerByIndex', 'getClassByIndex']),
    ...mapGetters('session', ['getUserAnswer']),
  },
  methods: {
    clearGame() {
      this.$store.dispatch('session/clear');
      this.toggle = false;
    },
    checkGame() {
      this.$store.dispatch('game/checkGameAll');
      let count = 0;
      if (this.session.input) {
        for (let i = 0; i < this.session.input.length; i += 1) {
          const { id, value } = this.session.input[i];
          if (parseInt(value, 10) === this.getAnswerByIndex(id)) {
            count += 1;
          }
        }
        if (count + Object.keys(this.game.revealed).length === 26) {
          const data = {
            display: true,
          };
          this.$store.dispatch('game/showModalComplete', data);
        }
      }
    },
    async printGame() {
      const clientLogo = `${process.env.VUE_APP_API_URL}/storage/${this.game.logo}?no_download=true`;
      const imgUrl = await Vue.prototype.$axios.get(clientLogo);
      const dataRes = await this.toDataURL(imgUrl.data);
      const srcImg = dataRes.url;
      const ratio = dataRes.height / dataRes.width;

      const doc = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      const height = 30;
      const width = (height / ratio);
      const marginX = (pdfWidth - width) / 2;
      doc.addImage(srcImg, 'JPEG', marginX, 7, width, height);
      doc.setLineDash([1, 1], 0);
      doc.setLineWidth(1);
      doc.setDrawColor(this.game.color);
      doc.line(10, height + 10, 200, height + 10);
      doc.setLineDash(0);
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(30);
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(30);
      doc.text('Alfakodo', 105, height + 30, 'center');
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(12);
      doc.text('Letters A to Z have a number value', 105, height + 40, 'center');
      doc.text('Some are shown in the right hand cells', 105, height + 46, 'center');
      doc.text('Create remaining values using clues in centre cells', 105, height + 52, 'center');

      const stylesTable = {
        font: 'helvetica',
        fontStyle: 'bold',
        halign: 'center',
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
      };
      const startingPage = doc.internal.getCurrentPageInfo().pageNumber;
      const clueRowsLeft = [];
      _.forEach(this.getBySide('left'), (clue, index) => {
        const valClue = this.isClueRevealed(index) ? this.getAnswerByIndex(index) : '';
        const row = [index, clue, valClue];
        clueRowsLeft.push(row);
      });

      doc.autoTable({
        theme: 'grid',
        body: clueRowsLeft,
        showHead: 'never',
        startY: height + 60,
        columnStyles: {
          0: {
            cellWidth: 20,
          },
          1: {
            cellWidth: 20,
          },
          2: {
            cellWidth: 20,
          },
        },
        styles: stylesTable,
        margin: { left: 40 },
      });

      doc.setPage(startingPage);

      const clueRowsRight = [];
      _.forEach(this.getBySide('right'), (clue, index) => {
        const valClue = this.isClueRevealed(index) ? this.getAnswerByIndex(index) : '';
        const row = [index, clue, valClue];
        clueRowsRight.push(row);
      });
      doc.autoTable({
        theme: 'grid',
        body: clueRowsRight,
        showHead: 'never',
        startY: height + 60,
        columnStyles: {
          0: {
            cellWidth: 20,
          },
          1: {
            cellWidth: 20,
          },
          2: {
            cellWidth: 20,
          },
        },
        styles: stylesTable,
        margin: { left: 110 },
      });

      doc.setFontSize(7);

      doc.text(`© Ken Egan - ${this.game.name}`, 155, height + 162, 'center');

      doc.setPage(startingPage);

      const answerRowsTop = [];
      const indexTmp = [];
      const answerTmp = [];
      _.forEach(this.getBySide('left'), (clue, index) => {
        indexTmp.push(index);
        answerTmp.push(this.getAnswerByIndex(index));
      });
      answerRowsTop.push(indexTmp);
      answerRowsTop.push(answerTmp);

      doc.autoTable({
        theme: 'grid',
        body: answerRowsTop,
        showHead: 'never',
        startY: pdfHeight - 62,
        columnStyles: {
          0: {
            cellWidth: 6,
          },
          1: {
            cellWidth: 6,
          },
          2: {
            cellWidth: 6,
          },
          3: {
            cellWidth: 6,
          },
          4: {
            cellWidth: 6,
          },
          5: {
            cellWidth: 6,
          },
          6: {
            cellWidth: 6,
          },
          7: {
            cellWidth: 6,
          },
          8: {
            cellWidth: 6,
          },
          9: {
            cellWidth: 6,
          },
          10: {
            cellWidth: 6,
          },
          11: {
            cellWidth: 6,
          },
          12: {
            cellWidth: 6,
          },
        },
        styles: {
          ...stylesTable,
          fontSize: 6,
        },
        margin: { left: 92 },
      });

      const answerRowsBottom = [];
      const indexTmpBottom = [];
      const answerTmpBottom = [];
      _.forEach(this.getBySide('right'), (clue, index) => {
        indexTmpBottom.push(index);
        answerTmpBottom.push(this.getAnswerByIndex(index));
      });
      answerRowsBottom.push(indexTmpBottom);
      answerRowsBottom.push(answerTmpBottom);

      doc.autoTable({
        theme: 'grid',
        body: answerRowsBottom,
        showHead: 'never',
        startY: pdfHeight - 47,
        columnStyles: {
          0: {
            cellWidth: 6,
          },
          1: {
            cellWidth: 6,
          },
          2: {
            cellWidth: 6,
          },
          3: {
            cellWidth: 6,
          },
          4: {
            cellWidth: 6,
          },
          5: {
            cellWidth: 6,
          },
          6: {
            cellWidth: 6,
          },
          7: {
            cellWidth: 6,
          },
          8: {
            cellWidth: 6,
          },
          9: {
            cellWidth: 6,
          },
          10: {
            cellWidth: 6,
          },
          11: {
            cellWidth: 6,
          },
          12: {
            cellWidth: 6,
          },
        },
        styles: {
          ...stylesTable,
          fontSize: 6,
        },
        margin: { left: 92 },
      });

      doc.text(`© Ken Egan - ${this.game.name}`, 157, pdfHeight - 32, 'center');

      doc.text(`© These crosswords and puzzles are protected by copyright and are the property of ${this.game.client_name}. No part of these`, 105, 285, 'center');
      doc.text(`features may be reproduced or onsold – in whole or in part – in any form without the written permission of ${this.game.client_name}`, 105, 288, 'center');

      doc.setDrawColor(0, 0, 0);
      doc.line(10, 290, 200, 290);

      doc.save(`${this.game.name}.pdf`);
    },
    toDataURL(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function convertBase64() {
          const canvas = document.createElement('CANVAS');
          const ctx = canvas.getContext('2d');
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          const dataURL = canvas.toDataURL();
          const dataRes = {
            width: this.naturalWidth,
            height: this.naturalHeight,
            url: dataURL,
          };
          resolve(dataRes);
        };
        img.onerror = reject;
        img.src = src;
        if (img.complete || img.complete === undefined) {
          img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
          img.src = src;
        }
      });
    },
  },
};
</script>
