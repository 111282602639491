<template>
  <div>
    <div v-for="(clue, index) in getBySide(side)" class="gameRow" v-bind:key="index">
      <div class="gameItem index"><span v-text="index"></span></div>
      <div class="gameItem clue"><span v-text="clue"></span></div>
      <div class="gameItem animated">
        <input class="answer"
          :class="getClassByIndex(index)"
          :ref="index"
          :id="index"
          maxlength="2"
          @keydown="handleUserInput"
          @click.prevent="setInput"
          readonly
          :value="(isClueRevealed(index) ? getAnswerByIndex(index) : '') ||
            (getUserAnswer(index) ? getUserAnswer(index).value : '')"
          :disabled="isClueRevealed(index)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: ['side'],
  computed: {
    ...mapState('game', ['game', 'checkedGame']),
    ...mapState('session', ['session', 'answers']),
    ...mapGetters('game', ['isClueRevealed', 'getBySide', 'getAnswerByIndex', 'getClassByIndex']),
    ...mapGetters('session', ['getUserAnswer']),
  },
  watch: {
    checkedGame(value) {
      if (this.$refs[value.id]) {
        this.$refs[value.id][0].classList.add(value.type);
        setTimeout(() => {
          this.$refs[value.id][0].classList.remove(value.type);
        }, 3000);
      }
    },
  },
  methods: {
    async handleUserInput(e) {
      // Filter out non-numeric input.
      e.preventDefault();
      if (/^[0-9]$/i.test(e.key)) {
        // Store user input in session.
        const { id } = e.target;
        const value = (this.getUserAnswer(id) ? this.getUserAnswer(id).value : '') + e.key;
        const input = {
          id,
          value,
        };
        await this.$store.dispatch('session/startTimer');
        this.$store.dispatch('session/addInput', input);
        if (this.session.input) {
          const inputFilter = this.session.input.filter((answer) => answer.value !== '');
          const countInput = inputFilter.length;
          const countRevealed = Object.keys(this.game.revealed).length;
          let count = 0;
          if (countInput + countRevealed === 26) {
            this.$store.dispatch('game/checkGameAll');
            for (let i = 0; i < countInput; i += 1) {
              const sessionId = this.session.input[i].id;
              const sessionValue = this.session.input[i].value;
              if (parseInt(sessionValue, 10) === this.getAnswerByIndex(sessionId)) {
                count += 1;
              }
            }
            if (count + countRevealed === 26) {
              const data = {
                display: true,
              };
              this.$store.dispatch('game/showModalComplete', data);
            }
          }
        }
      }
    },
    setInput(e) {
      this.$store.dispatch('session/setCell', e.target.id);
    },
  },
};
</script>
